import { Box, Flex } from '@hometime-ui/core'
import { extractPageDataForSEO } from '~/helpers/dataHelpers'
import { graphql } from 'gatsby'
import ContactUsBlurb from '~/components/ContactUsBlurb'
import ContactUsForm from '~/components/ContactUsForm'
import PageWrapper from '~/components/PageWrapper'
import React from 'react'
import SEO from '~/components/SEO'
import WidthWrapper, { widthWrapperPX } from '~/components/WidthWrapper'

export default function ContactUsPage({ location, data }) {
  return (
    <PageWrapper hideBottomCta>
      <SEO
        {...extractPageDataForSEO(data.contentfulPage)}
        pathname={location.pathname}
      />
      <Box
        bg={{
          base: 'none',
          lg: 'linear-gradient(90deg, #F8F8FA 43%, #FAFBFB 43%);',
        }}
      >
        <WidthWrapper
          px={{ ...widthWrapperPX, base: 0, md: 0 }}
          maxW={{ base: 'none', lg: '1290px' }}
        >
          <Flex flexDirection={{ base: 'column-reverse', lg: 'row' }}>
            <Box bg="gray.200" flex={{ base: 0, lg: 0.7 }}>
              <WidthWrapper
                px={{ ...widthWrapperPX, lg: 0, xl: 0 }}
                maxW={{ base: 'none', lg: '1290px' }}
                h={{ lg: '100%' }}
              >
                <ContactUsBlurb h="100%" />
              </WidthWrapper>
            </Box>
            <Flex
              justify="center"
              textAlign="center"
              flex={{ base: 0, lg: 1 }}
              w="full"
              pb={{ base: 10, lg: 20 }}
              px={{ base: 5, lg: 0 }}
            >
              <Box w="full" mx="auto" pl={{ lg: 7 }}>
                <ContactUsForm />
              </Box>
            </Flex>
          </Flex>
        </WidthWrapper>
      </Box>
    </PageWrapper>
  )
}

export const contactUsPageQuery = graphql`
  query ContactUsPageQuery {
    contentfulPage(name: { eq: "Contact Us" }) {
      ...basicPageInfo
    }
  }
`
