import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Textarea,
} from '@hometime-ui/core'
import React from 'react'

import FieldWrapper from './FieldWrapper'

function TextField({
  type = 'text',
  textarea = false,
  inputLeft = null,
  inputRight = null,
  inputMask = null,
  inputRightElementProps = null,
  inputLeftElementProps = null,
  ...props
}) {
  return (
    <FieldWrapper {...props}>
      {(field, meta, form, props) => {
        return (
          <InputGroup>
            <Input
              as={textarea ? Textarea : null}
              type={type}
              disabled={form.isSubmitting || props.disabled}
              {...field}
              {...props}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e)
                }

                let value = e?.target?.value

                if (inputMask) {
                  value = inputMask(value)
                }

                form.setFieldValue(props.name, value)
              }}
            />
            {inputLeft && (
              <InputLeftElement
                zIndex="1"
                width="auto"
                {...inputLeftElementProps}
              >
                {inputLeft}
              </InputLeftElement>
            )}
            {inputRight && (
              <InputRightElement
                zIndex="1"
                width="auto"
                {...inputRightElementProps}
              >
                {inputRight}
              </InputRightElement>
            )}
          </InputGroup>
        )
      }}
    </FieldWrapper>
  )
}

export default TextField
