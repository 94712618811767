import MobileNumberInput from '@hometime-ui/mobile-number-input'
import React from 'react'

import FieldWrapper from './FieldWrapper'

export default function MobileNumberField(props) {
  return (
    <FieldWrapper {...props}>
      {(field, meta, form, props) => {
        return (
          <MobileNumberInput
            isDisabled={form.isSubmitting}
            value={field.value}
            fontSize="sm"
            onBlur={(e) => {
              const value = e?.target?.value

              form.setFieldTouched(props.name, true)
              form.setFieldValue(props.name, value)
            }}
            {...props}
          />
        )
      }}
    </FieldWrapper>
  )
}
