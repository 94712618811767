const getCookie = (name) => {
  var value = '; ' + document.cookie
  var parts = value.split('; ' + name + '=')
  if (parts.length == 2) {
    return parts.pop().split(';').shift()
  }

  return null
}

export const createHubspotPayloadFromFormFieldKeys = (keys, values) => {
  const hutkCookie = getCookie('hubspotutk')
  const hutk =
    !hutkCookie || !window._isProduction
      ? '5437a95ab391a1be0df40374891b0ac4'
      : hutkCookie
  const hubspotPayload = {
    context: {
      hutk,
      pageUri: window.location.href,
      pageName: document.title,
    },
    fields: [],
  }
  keys.forEach((key) => {
    if (
      (values[key] === null || values[key] === undefined) &&
      key !== 'email'
    ) {
      return
    }
    hubspotPayload.fields.push({
      name: key,
      value: values[key] && values[key].value ? values[key].value : values[key],
    })
  })
  return hubspotPayload
}

export const getReferralCodeFromUrl = () => {
  const getURL = window.location.search
  const urlParams = new URLSearchParams(getURL)
  const referral_code = urlParams.get('referral_code')

  return referral_code
}
