import React from 'react'
import Select from '@hometime-ui/select'

import FieldWrapper from './FieldWrapper'

export default function SimpleSelectField(props) {
  return (
    <FieldWrapper {...props}>
      {(field, meta, form, props) => {
        return (
          <Select
            {...props}
            onChange={(option) => {
              if (props.onChange) {
                props.onChange(option)
              }

              form.setFieldTouched(props.name, true)

              if (option == null) {
                form.setFieldValue(props.name, null)
                return
              }

              if (
                option.value ||
                option.value === 0 ||
                option.value === false
              ) {
                form.setFieldValue(props.name, option.value)
              }
            }}
            value={props.options.find((o) => o.value === field.value) ?? null}
            isDisabled={form.isSubmitting || props.disabled}
          />
        )
      }}
    </FieldWrapper>
  )
}
