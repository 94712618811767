import * as Yup from 'yup'
import { Box, Button, Card, Heading, SimpleGrid, Text } from '@hometime-ui/core'
import { composeRequiredFieldErrorMsg } from '~/helpers/validation'
import {
  createHubspotPayloadFromFormFieldKeys,
  getReferralCodeFromUrl,
} from '~/helpers/formHelpers'
import { fetchIPInfo } from '~/helpers/dataHelpers'
import { Form, Formik } from 'formik'
import { MdArrowForward } from 'react-icons/md'
import { navigate } from 'gatsby'
import axios from 'axios'
import MobileNumberField from './Form/MobileNumberField'
import React, { useEffect, useState } from 'react'
import SimpleSelectField from './Form/SelectField'
import TextField from './Form/TextField'
import useScreen from '~/hooks/useScreen'

export const OrgPropertySize = {
  one_to_five: '1-5 properties',
  six_to_ten: '6-10 properties',
  eleven_to_twenty: '11-20 properties',
  twenty_one_to_fifty: '21-50 properties',
  fifty_one_to_one_hundred: '51-100 properties',
  one_hundred_plus: '100+ properties',
}

const schema = Yup.object().shape({
  firstname: Yup.string().required(composeRequiredFieldErrorMsg('First name')),
  lastname: Yup.string().required(composeRequiredFieldErrorMsg('Last name')),
  email: Yup.string().email().required(composeRequiredFieldErrorMsg('Email')),
  phone: Yup.string()
    .test('len', 'Mobile number must be valid.', (val) =>
      val ? val.length > 5 : false
    )
    .required(composeRequiredFieldErrorMsg('Mobile number')),
  company: Yup.string().required(composeRequiredFieldErrorMsg('Company')),
  company_size: Yup.string().required(
    composeRequiredFieldErrorMsg('Property count')
  ),
})

const INITIAL_VALUES = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  company: '',
  company_size: '',
  referral_code: '',
}

export default function ContactUsForm() {
  const { isMobile } = useScreen()
  const [countryCode, setCountryCode] = useState('US')
  const [isLocationLoading, setIsLocationLoading] = useState(true)

  useEffect(() => {
    fetchIPInfo()
      .then(({ data }) => {
        setCountryCode(data.countryCode)
      })
      .catch((error) => {
        console.error('Error', error)
      })
      .finally(() => {
        setIsLocationLoading(false)
      })
  }, [])

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {(formikProps) => {
        return (
          <Form>
            <Box maxW="544px" mx="auto">
              <Heading
                level={isMobile ? 2 : 1}
                color="gray.900"
                mt={{ base: 10, md: 14 }}
              >
                Get Started
              </Heading>
              <Text
                color="gray.700"
                mt={4}
                pb={{ base: 6, md: 12 }}
              >{`We need a few details to get going and setup your profile`}</Text>
            </Box>
            <Card maxW={{ base: '620px', lg: '520px' }} mx="auto">
              <Box px={{ base: 4, md: 8 }} py="8">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing="8" mb="6">
                  <TextField
                    name="firstname"
                    label="First name"
                    placeholder="Enter your first name"
                  />
                  <TextField
                    name="lastname"
                    label="Last name"
                    placeholder="Enter your last name"
                  />
                </SimpleGrid>
                <Box mb="6">
                  <TextField
                    type="email"
                    name="email"
                    label="Email address"
                    placeholder="Enter your email address"
                  />
                </Box>
                <Box mb="8">
                  <MobileNumberField
                    name="phone"
                    label={'Mobile'}
                    placeholder="000 000 000"
                    defaultCountry={countryCode}
                    disabled={isLocationLoading}
                  />
                </Box>
                <SimpleGrid
                  columns={{ base: 1, md: 2 }}
                  spacing="8"
                  mb="6"
                  textAlign="left"
                >
                  <TextField
                    name="company"
                    label="Company"
                    placeholder="Enter your company name"
                  />
                  <SimpleSelectField
                    data-testid="company-size"
                    label="No. of properties"
                    name="company_size"
                    options={Object.keys(OrgPropertySize).map((k) => ({
                      label: OrgPropertySize[k],
                      value: k,
                    }))}
                    isSearchable={false}
                  />
                </SimpleGrid>
                <TextField
                  name="message"
                  label="Message"
                  placeholder="What area of your business do you need assistance with?"
                  mb="6"
                  textarea
                  optional
                />
                <Box textAlign="center">
                  <Button
                    type="submit"
                    bg="teal.300"
                    color="white"
                    fontWeight="700"
                    px="6"
                    py={{ base: 2, lg: 3 }}
                    alignSelf="center"
                    minH="10"
                    disabled={!formikProps.isValid || formikProps.isValidating}
                    rightIcon={<MdArrowForward />}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )

  function onSubmit(values, actions) {
    const referralCode = getReferralCodeFromUrl()

    if (referralCode) {
      values.referral_code = referralCode
    }

    const fields = [
      'firstname',
      'lastname',
      'email',
      'phone',
      'company',
      'company_size',
      'message',
      'referral_code',
    ]
    const payload = createHubspotPayloadFromFormFieldKeys(fields, values)

    return axios
      .post(
        'https://api.hsforms.com/submissions/v3/integration/submit/3432602/26eff7ae-1a65-472e-83cc-630074b9b421',
        payload
      )
      .then(() => {
        window.gtag('event', 'form_submission', {
          email: values.email,
          property_count: values.company_size,
        })
        navigate('/book-demo/', {
          state: { values },
        })
      })
      .catch(() => {
        alert('Failed to submit form')
      })
  }
}
