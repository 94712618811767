import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Text,
  Tooltip,
} from '@hometime-ui/core'
import { FastField, Field } from 'formik'
import { MdHelpOutline } from 'react-icons/md'
import React from 'react'

function FieldWrapper({ children, ...props }) {
  const {
    label,
    subLabel,
    helpText,
    optional = false,
    optionalText = 'Optional',
    tooltipText,
    tooltipTextProps = {},
    renderError,
    hayai = false,
    wrapperProps,
  } = props

  const Wrapper = hayai ? FastField : Field
  const getErrorArrFromErrorObj = (errorObj) => {
    return Object.values(errorObj).reduce((errorArr, errorVal) => {
      if (
        errorVal &&
        !Array.isArray(errorVal) &&
        typeof errorVal === 'object'
      ) {
        return [...errorArr, getErrorArrFromErrorObj(errorVal)]
      }

      return [...errorArr, errorVal]
    }, [])
  }
  return (
    <Wrapper name={props.name}>
      {({ field, form, meta }) => {
        const hasError = meta.touched && meta.error
        return (
          <FormControl isInvalid={hasError} {...wrapperProps}>
            {label && renderLabel()}
            {children(field, meta, form, props)}
            {hasError && !!renderError && renderError(meta.error)}
            {hasError && !renderError && (
              <FormErrorMessage mt="1" fontSize="xs">
                {typeof meta.error === 'string' && meta.error}
                {Array.isArray(meta.error) && meta.error.join(', ')}
                {typeof meta.error === 'object' &&
                  meta.error !== null &&
                  !Array.isArray(meta.error) &&
                  getErrorArrFromErrorObj(meta.error).join(', ')}
              </FormErrorMessage>
            )}
            {helpText && (
              <FormHelperText mt="1" fontSize="xs">
                {helpText}
              </FormHelperText>
            )}
          </FormControl>
        )
      }}
    </Wrapper>
  )

  function renderLabel() {
    return (
      <FormLabel>
        <Flex>
          {label}
          {optional && (
            <Text fontWeight="normal" ml="1">
              ({optionalText})
            </Text>
          )}
          {tooltipText && (
            <Tooltip
              label={tooltipText}
              hasArrow
              placement="top"
              zIndex="1"
              {...tooltipTextProps}
            >
              <Box as="span" ml="1" mt="-1px" position="relative">
                <Icon as={MdHelpOutline} color="gray.600" fontSize="md" />
              </Box>
            </Tooltip>
          )}
        </Flex>
        {subLabel && (
          <Box fontWeight="normal" mt="-4px">
            <Text fontSize="xs" color="gray.600">
              {subLabel}
            </Text>
          </Box>
        )}
      </FormLabel>
    )
  }
}

export default React.memo(FieldWrapper)
